import React, { useState } from 'react';
import { Container } from '@mui/material';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import SignInOptionsPage from './SignInOptionsPage'; // Updated import
import VerificationPage from './VerificationPage'; // Updated import
import MapBackground from './Map-Background.png';
import { useTranslation } from 'react-i18next';
import {
	Divider,
	Paper,
	TextField,
  } from '@mui/material';
  import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
  import { Fragment, useEffect} from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { validateForm } from '../Dashboard/formValidation';
  import CssBaseline from '@mui/material/CssBaseline';
  import Alert from '../../components/Alert/Alert';
  import { axiosPrivate } from '../../apis/backend';
  
  import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Snackbar,
	Typography,
  } from '@mui/material';
  import {
	MyResourcesList,
	StyledDropzone,
	HoursModal,
	PendingResourcesList,
	UsersList,
	ApprovedResourcesList,
	Map,
  } from '../../components';
  
  import { createTheme, ThemeProvider } from '@mui/material/styles';
  import { lightBlue } from '@mui/material/colors';
  import { userActions } from '../../store/auth-slice';
const backgroundStyle = {
  backgroundImage: `url(${MapBackground})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};
const defaultTheme = createTheme();
function EmailSignIn({ callbackIdP }) {
	const user = useSelector((state) => state.auth.user);
  // console.log(user);
  const [emailVerify, setEmailVerify] = useState(false);

  const handleNext = () => {
    setEmailVerify(true);
  };

  const handleReturn = () => {
    setEmailVerify(false);
  };
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    orgName: '',
    email: '',
    orgAddress: '',
    orgPhone: '',
    website: '',
    jobTitle: '',
    additionalInformation: '',
  });

  const [errObject, setErrObject] = useState(undefined);

  const handleInputChange = (fieldName, e) => {
    console.log('Before update:', formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: e.target.value,
    }));
    console.log('After update:', formData);
  };

  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  const sx = {
    margin: '0.7rem 0',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid #00233D',
      borderRadius: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00233D',
        borderWidth: '2px',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '1.6rem',
      padding: '1rem 1rem',
    },
    padding: '0 0 0.8rem 0',
  };

  const formFields = [
	{
		name: 'First Name',
		disabled: user?.firstName,
		id: 'firstName',
		placeholder: user?.firstName,
		autoComplete: 'first-name',
	  },
	  {
		name: 'Last Name',
		disabled: user?.lastName,
		id: 'lastName',
		placeholder: user?.lastName,
		autoComplete: 'last-name',
	  },
    {
      name: 'Email',
      disabled: user?.email,
      id: 'email',
      placeholder: user?.email,
      autoComplete: 'email',
    }
  ];

  const sendData = async (data) => {
	console.log(data)
    try {
      const response = await axiosPrivate.post('/user/emailRegistration', data);
      if (response?.data?.errCode === '0') {
        window.location.href = '/dashboard';
      } else {
        throw Error;
      }
    } catch (error) {
      setErrObject({ title: 'Oops! We\'ve encountered a problem', message: error?.response?.data?.errMsg || 'An unexpected error occurred. Please try again later.' });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data
	if (formData.email.trim() === '') {
		console.error('An error occurred during form submission:', 'Email Address is required');
	} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
		console.error('An error occurred during form submission:', 'Invalid Email Address');
	}

    // Send the form data to the server using postData function
    const dataToSend = {
		firstName: formData.firstName,
		lastName: formData.lastName,
		orgName: '',
		email: formData.email,
		orgAddress: '',
		orgPhone: '',
		website: '',
		jobTitle: '',
		additionalInformation: '',
    };

    // Call the sendData function to make the POST request
    try {
      await sendData(dataToSend);
	  window.location.href = "/dashboard";
    } catch (error) {
      console.error('An error occurred during form submission:', error.message);
      // Handle the error as needed
    }
  };

  

  return (
    <>
	<Header/>
        <Box sx={{ pb: '30px' }}>
          <Container
            maxWidth="lg"
            style={{ border: '1px solid #CACCD3', borderRadius: '10px' }}
          >
            <CssBaseline />
            <Box
              sx={{
                mb: '2rem',
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" onSubmit={handleSubmit} noValidate>

                {(errObject) && (
                  <Grid item xs={12} paddingBottom={3}>
                    <Alert data={errObject} />
                  </Grid>
                )}

                {formFields.map((formField) => {
                  const textFieldStyles = {
                    borderRadius: '1.2rem',
                    fontSize: '1.8rem',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '24px',
                  };
                  const redAsteriskStyles = {
                    ...textFieldStyles,
                    color: 'red',
                  };

                  return (
                    <Fragment key={formField.id}>
                      <Typography variant="h5" sx={{ fontWeight: '500' }}>
                        <span style={textFieldStyles}> {formField.name}</span>
                        {formField.required && <span style={redAsteriskStyles}> *</span>}
                      </Typography>
                      <TextField
                        disabled={formField.disabled}
                        required={formField.required}
                        multiline={formField.multiline}
                        fullWidth
                        id={formField.id}
                        placeholder={formField.placeholder}
                        autoComplete={formField.autoComplete}
                        error={formErrors[formField.id]}
                        helperText={formErrors[formField.id]}
                        value={formData[formField.id]} // Use the value from formData
                        onChange={(e) => handleInputChange(formField.id, e)} // Pass the property name to handleInputChange
                        InputProps={{
                          style: {
                            borderRadius: '1.2rem',
                            fontSize: '1.8rem',
                          },
                        }}
                        sx={{ ...sx }}
                      />
                    </Fragment>
                  );

                })}
                <Box
                  sx={{
                    mb: '2rem',
                    marginTop: '2rem',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >

                  <Button
                    variant="contained"
                    style={{
                      borderColor: "#B3BAC5",
                      width: '320px',
                      display: "flex",
                      borderRadius: '12px',
                      padding: '17px 20px',
                      boxSizing: 'border-box',

                    }}
                    type="submit"
                    fullWidth="true"
                    onClick={handleSubmit}
                  >
                    <div
                      style={{
                        color: '#FFFFFF',
                        textAlign: 'center', // Note the correct spelling of textAlign
                        fontFamily: 'Nunito', // Note the correct spelling of fontFamily
                        fontSize: '18px', // Note the correct spelling of fontSize
                        fontStyle: 'normal', // Note the correct spelling of fontStyle
                        fontWeight: '700', // Use fontWeight to make the text bold
                        lineHeight: '20px',
                        textTransform: 'none',
                      }}
                    >
                      Submit
                    </div>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
		<Footer/>
    </>
  );

}

export default EmailSignIn;
