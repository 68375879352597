import React from 'react';
import { SearchBar } from '../SearchBar/SearchBar';
import { Logo } from '../Logo/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enteredTermActions } from '../../store/enteredTerm-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';
import { userActions } from '../../store/auth-slice';
import { coordsActions } from '../../store/coords-slice';
import { axiosPrivate } from '../../apis/backend';
import { useTranslation } from "react-i18next";
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';

export function Header({ landing, setIsSearching, dashboard, searchBar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authed = user?.roles?.includes('100001');

  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      axiosPrivate.post('/auth/signOut')
    } catch (error) {
    } finally {
      dispatch(userActions.setUser(undefined));
    }
  };

  const handleClear = () => {
    dispatch(enteredTermActions.handleSearchInput(''));
    dispatch(enteredLocationActions.handleLocationInput(''));
    dispatch(coordsActions.logCoords(undefined));
  };

  if (landing) {
    return (
      <div className="header-landing">
        <div className="header-landing__desktop">
          <div className="header-landing__head">
            <Logo />
            <div className="header-landing__btnwrapper">
              {/* <span className="header-landing__language">Español</span> */}
              <LanguageSelector />
              {authed && (
                <span
                  className="header-landing__signout"
                  onClick={() => navigate(`/dashboard`)}
                >
                  {t('Dashboard')}
                </span>
              )}
              {authed ? (
                <span className="header-search__signout" onClick={handleSignOut}>
                  {t('Sign Out')}
                </span>
              ) : (
                <span className="header-search__signout"
                  onClick={() => navigate(`/auth/signin`)}
                >
                  {t('Sign In')}
                </span>
              )
              }
            </div>
          </div>
          <div className="header-search__mobile">
            <SearchBar
              className="header-search__search-mobile"
              setIsSearching={setIsSearching}
              currentPage="landing"
            />
          </div>
          <div className="header-landing__container">
            <div className="header-landing__chapter">
              <div className="header-landing__welcome heading-primary">
                {t('Find local programs that provide the help you need.')}
              </div>
              <div className="header-landing__description">
                {t('Whether it’s food, housing, health care, financial assistance or more.')}
              </div>
              <div className="header-landing__search-wrapper">
                <SearchBar
                  className="header-landing__search"
                  setIsSearching={setIsSearching}
                  currentPage="landing"
                />
              </div>
            </div>
            <div className="header-landing__illustration">
              <img decoding="async" loading="eager" src="/media/landing-illustration.svg" title="Decorative illustration about shelter, food, and medical resources" alt="Decorative illustration about shelter, food, and medical resources" />
            </div>
          </div>
        </div>
        <div className="header-landing__mobile">
          <SearchBar
            className="header-landing__search-mobile"
            setIsSearching={setIsSearching}
            currentPage="search"
          />
        </div>

        <div className="header-landing__browse">
          <p
            className="header-landing__browse-text"
            onClick={() => {
              navigate('/search');
            }}
          >
            {t('Browse all')} {'>'}
          </p>
        </div>
      </div>
    );
  } else if (dashboard) {
    return (
      <div className="header-dashboard">
        <div className="header-dashboard__desktop">
          <Link to="/" onClick={handleClear}>
            <Logo />
          </Link>
          <div className="header-dashboard__btnwrapper">
            {/* <div className="header-search__language">Español</div> */}
            <LanguageSelector />
            {authed && (
              <span
                className="header-dashboard__signout"
                onClick={() => navigate('/')}
              >
                {t('Home')}
              </span>
            )}
            {authed && (
              <a
                className="header-dashboard__signout"
                href="/"
                onClick={handleSignOut}
              >
                {t('Sign Out')}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="header-search">
      <div className="header-search__left-content">
        <div className="header-search__desktop">
          <Link to="/" onClick={handleClear}>
            <Logo />
          </Link>
          {searchBar && (
            <div className="header-search__search-wrapper">
              <SearchBar className="header-search__search" setIsSearching={setIsSearching} currentPage="search" />
            </div>
          )}
        </div>
        <div className="header-search__btnwrapper">
          {/* <div className="header-search__language">Español</div> */}
          <LanguageSelector />
          {authed && (
            <span
              className="header-search__signout"
              onClick={() => navigate(`/dashboard`)}
            >
              {t('Dashboard')}
            </span>
          )}
          {authed ? (
            <span className="header-search__signout" onClick={handleSignOut}>
              {t('Sign Out')}
            </span>
          ) : (
            <span className="header-search__signout"
              onClick={() => navigate(`/auth/signin`)}
            >
              {t('Sign In')}
            </span>
          )
          }
        </div>
      </div>
      <div className="header-search__mobile">
        <SearchBar
          className="header-search__search-mobile"
          setIsSearching={setIsSearching}
          currentPage="search"
        />
      </div>
    </div>
  );
}
