import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';
import { Pills } from '../Pills/Pills';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';
import {Typography } from '@mui/material';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import { axiosPrivate } from '../../apis/backend';
import axios from '../../apis/backend';



export function Card({
  id,
  index,
  header,
  address,
  distance,
  website,
  phone_string,
  phone,
  tags,
  services,
  hours,
  active,
  classScroll,
  verifiedBy,
  timeLastUpdate,
  description,
}) {
  const [isSaved, setIsSaved] = useState(false); // State to track save status
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Fetch Saved Resources on load
  useEffect(() => {
    const checkIfSaved = async () => {
      try {
        const response = await axiosPrivate.get('/user/savedResources');
        if (response.status === 200 && response.data.errCode === '0') {
          const savedResources = response.data.payload.resources;
          const isResourceSaved = savedResources.some((resource) => resource.resourceId === id);
          setIsSaved(isResourceSaved);
        }
      } catch (error) {
        console.error('Error checking saved resources:', error);
      }
    };

    checkIfSaved();
  }, [id]);


  const currentDay = (new Date().getDay() - 1) % 7;

  function truncateText(text, minLength) {
    if (text.length <= minLength) {
      return text;
    }
    const truncated = text.substring(0, minLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
      return text.substring(0, lastSpaceIndex) + '...';
    }
    return truncated + '...';
  }
  
  function handleShareClick(e) {
    e.stopPropagation(); // Prevents the card click event from firing
    
    const detailsUrl = `${window.location.origin}/resource/${id}`;
    const subject = encodeURIComponent("Check out this resource!");
    const body = encodeURIComponent(`Hi,
  
  I wanted to share this great resource with you. Check it out here: ${detailsUrl}
  
  Best regards,
  [Your Name]`);
  
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
  
    window.location.href = mailtoLink;
  }
  
  
  const handleSaveClick = async (e) => {
    e.stopPropagation(); // Prevents the card click event from firing

    if (isSaved) {
      // Unsave functionality
      try {
        const response = await axiosPrivate.delete(`/user/savedResource?resourceId=${id}`);
        if (response.status === 200) {
          setIsSaved(false); // Mark the resource as unsaved
        }
      } catch (error) {
        console.error('Failed to unsave resource:', error);
      }
    } else {
      // Save functionality
      try {
        const response = await axiosPrivate.post(`/user/savedResource?resourceId=${id}`);
        if (response.status === 200) {
          setIsSaved(true); // Mark the resource as saved
        }
      } catch (error) {
        console.error('Failed to save resource:', error);
      }
    }
  }


  function handleClick(e) {
    e.stopPropagation();  
  }

  function parseDate(dateString) {
        const date = new Date(dateString);
        const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-based month index
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();

        return `${month}/${day}/${year}`;
    }

  let openTime, closeTime;
  if (hours && hours[currentDay]) {
    // Get the opening and closing hours for the current day
    const { open, close } = hours[currentDay];
    openTime = open;
    closeTime = close;
  }

  const formatPhoneNumber = (number) => {
    const cleaned = `${number}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };

  const minutesFromMidnight = (hours, minutes) => hours * 60 + minutes;

  const isOpenNow = (currentTime, openTimeInMinutes, closeTimeInMinutes) => {
    if (openTimeInMinutes < closeTimeInMinutes) {
      return currentTime >= openTimeInMinutes && currentTime < closeTimeInMinutes;
    }
    // Hours cross midnight
    return currentTime >= openTimeInMinutes || currentTime < closeTimeInMinutes;
  };

  const formatTime12Hour = (hours, minutes) => {
    const period = hours < 12 ? 'AM' : 'PM';
    const adjustedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${adjustedHours}:${formattedMinutes} ${period}`;
  };

  const getOrgStatus = (currentTime) => {
    if (!openTime || !closeTime) return;
    const openTimeInMinutes = minutesFromMidnight(openTime.hours, openTime.minutes);
    const closeTimeInMinutes = minutesFromMidnight(closeTime.hours, closeTime.minutes);

    if (!isOpenNow(currentTime, openTimeInMinutes, closeTimeInMinutes)) {
      return {
        variant: 'error',
        text: `${t('Closed • Opens at')} ${formatTime12Hour(openTime.hours, openTime.minutes)}`,
      };
    }

    const minutesUntilClosing =
      closeTimeInMinutes > currentTime
        ? // Same day
        closeTimeInMinutes - currentTime
        : // Next day
        1440 - currentTime + closeTimeInMinutes;

    if (minutesUntilClosing <= 60) {
      return {
        variant: 'warning',
        text: `${t('Closing Soon • Closes at')} ${formatTime12Hour(
          closeTime.hours,
          closeTime.minutes
        )}`,
      };
    }
    return {
      variant: 'success',
      text: `${t('Open Now • Closes at')} ${formatTime12Hour(closeTime.hours, closeTime.minutes)}`,
    };
  };

  const now = new Date();
  const currentTime = minutesFromMidnight(now.getHours(), now.getMinutes());
  const orgStatus = getOrgStatus(currentTime);

  

  

  return (
    <div
      className={`card ${active && 'card__active'} ${classScroll}`}
      onClick={() => navigate(`/resource/${id}?distance=${distance}`)}
    >
      <div className="card__left">
        <div className="card__header">
          <div style={{maxWidth: "85%"}}>
          {index}. {header ? header : 'Unknown'}
          </div>
          {verifiedBy && verifiedBy !== "" && (
            <svg style={{ marginLeft: 10, marginBottom: 5 }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20" fill="none">
              <circle cx="10" cy="10" r="7.375" stroke="#2067D2" stroke-width="1.25" />
              <path d="M6.49976 10L8.99976 12.5L13.4998 8" stroke="#2067D2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          )}
        </div>
        <div style={{ display: "flex", marginBottom: 10 }}>

{(verifiedBy && verifiedBy !== "") &&
    <div style={{ display: "flex" }}>

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">

            <circle cx="10" cy="10" r="7.375" stroke="#2067D2" stroke-width="1.25" />
            <path d="M6.49976 10L8.99976 12.5L13.4998 8" stroke="#2067D2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <Typography className="regText" style={{ color: "#2067D2" }}>Verified Program</Typography>

        <svg style={{ marginTop: 7, marginLeft: 8, marginRight: 8 }} xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
            <circle cx="1.5" cy="2" r="1.5" fill="#696B72" />
        </svg>
    </div>

}
<Typography className="regText">Last updated {parseDate(timeLastUpdate)}</Typography>



</div>
{description && description.length > 0 && <Typography className="regText">{description && description.length > 0 ? truncateText(description, 150) : ""}</Typography> }
        {/* {address?.street === "Online" ||
          <div className="card__address">
            <a
              href={`https://www.google.com/maps/place/${encodeURIComponent(`${address?.street}, ${address?.city}, ${address?.state}, ${address?.zipCode}`)}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <div>{address?.street}</div>
              <div>{`${address?.city}, ${address?.state} ${address?.zipCode}`}</div>
            </a>
            <div className="card__distance">{distance}</div>
          </div>
        } */}

        

        {/* <div className="card__website">
          <IonIcon name="earth" className="card__icon" />
          {website ? (
            <a
              style={{ wordWrap: "break-word", }}
              className="card__website"
              href={
                website.startsWith('http://') || website.startsWith('https://')
                  ? website
                  : 'http://' + website
              }
              target="_blank"
              rel="noreferrer"
              onClick={handleClick}
            >
              {website}
            </a>
          ) : (
            'N/A'
          )}
        </div> */}

        {((phone?.areaCode && phone?.number) || phone_string) && (
          <div className="card__phone">
            <IonIcon name="call" className="card__icon" />
            <>
              {phone?.areaCode && phone?.number ? (
                <a href={`tel: +${phone.areaCode} ${phone.number}`} className="card__phone-link">
                  {`+${phone.areaCode} ${phone.number}`}
                </a>
              ) : phone_string && (
                <a href={`tel:${phone_string}`} className="card__phone-link">
                  {formatPhoneNumber(phone_string)}
                </a>
              )}
            </>
          </div>
        )}
        {services?.length > 0 && (
          <div className="card__services--wrapper">
            <div className="card__services">{t('Services')}</div>
            <div className="card__pills">
              <div className="card__pills-layout">
                {services.map((item, index) => (
                  <Pills key={index} variant="general" text={item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()} icon={item} />
                ))}
              </div>
            </div>
          </div>
        )}
        {tags?.length > 0 && (
          <div className="card__services--wrapper">
            <div className="card__services">{t('Tags')}</div>
            <div className="card__pills">
              <div className="card__pills-layout">
                {tags.map((item, index) => (
                  <Pills key={index} variant="general" text={item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()} icon={item} />
                ))}
              </div>
            </div>
          </div>
        )}

          <div className="card__buttons">
            <Button
              variant="primary" // This will be the blue button
              label="See Details"
              onClick={() => navigate(`/resource/${id}`)}
              iconName="external-link" // Assuming this adds the external link icon
            />
            <Button
            variant={isSaved ? 'yellow' : 'secondary'} // Change variant based on state
            label={isSaved ? 'Saved' : 'Save'}
            onClick={handleSaveClick}
            hasLightIcon={false}
            iconName="star"
            />
            <Button
              variant="secondary" // Use a secondary or tertiary variant for the outlined buttons
              label="Share"
              onClick={(e) => handleShareClick(e)}
              iconName="arrow" // Assuming this adds the share icon
              hasLightIcon={false}
            />
          </div>
          
      </div>
      <div className="card__right">
        {orgStatus && (
          <span className="card__hours-pill">
            <Pills variant={orgStatus.variant} text={orgStatus.text} />
          </span>
        )}
      </div>
    </div>
  );
}

Card.defaultProps = {
  index: 1,
  header: 'Header',
  address: 'Address street, City State Zip',
  distance: 'Distance from your location',
  website: 'website.com',
  phone: undefined,
  services: ['Free lunch', 'Free dinner', 'Adult education'],
  hours: [
    {
      day: 'Tuesday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Wednesday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Thursday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Friday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    {
      day: 'Saturday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
    { day: 'Sunday', openTime: null, closeTime: null },
    {
      day: 'Monday',
      openTime: { hours: 17, minutes: 0 },
      closeTime: { hours: 21, minutes: 0 },
    },
  ],
  active: true,
};

Card.propTypes = {
  index: PropTypes.number,
  header: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  distance: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  highlights: PropTypes.arrayOf(PropTypes.string),
  accessibility: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  hours:
    PropTypes.string ||
    PropTypes.arrayOf(
      PropTypes.shape({
        open: PropTypes.shape({
          hours: PropTypes.number,
          minutes: PropTypes.number,
        }),
        close: PropTypes.shape({
          hours: PropTypes.number,
          minutes: PropTypes.number,
        }),
      })
    ),
};
